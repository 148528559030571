.main-download-card {
  border-radius: 25px;
  width: 170px;
  height: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  background-color: #150442;
}

.document-download-card-title {
  text-align: center;
  font-weight: 500;
  color: #ffffff;
  font-size: 18px;
  margin-top: 10px;
}
