.sobre {
  display: grid; /*tag para alinhar tudo juntos*/
  align-items: center;
  grid-template-columns: 1fr 1fr;
  gap: 7%;
  color: #500281;
  padding: 0 12%;
  margin: 180px 0;
  scroll-margin-top: 150px;
}

.sobre > img {
  /* width: 42em; em para textos e rem para diversos (imagens e afins) */
  /* margin-bottom: 5rem; */
}

.sobre > div {
  /*criei outra div para poder manuseiar melhor nos textos*/
  display: block;
}

.titulo-sobrenos {
  width: 100%;
  margin-top: 0;
}

.subtitulo-sobrenos {
  margin-bottom: 0;
  color: #be00ff;
  font-weight: 300;
  font-style: italic;
}
.img-sobrenos {
  width: 100%;
  min-width: 558px;
}

@media screen and (max-width: 768px) {
  .sobre {
    display: flex;
    flex-direction: column-reverse;
  }
  .img-sobrenos {
    min-width: 275px;
  }
  .subtitulo-sobrenos {
    text-align: center;
  }
  .titulo-sobrenos {
    text-align: center;
  }
}
