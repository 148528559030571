.main-document-card {
  border: 1px solid #5b0390;
  border-radius: 25px;
  width: 170px;
  height: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px;
  filter: drop-shadow(-4px 7px 3px #37014957);
  background-color: rgb(255, 255, 255);
}

.document-card-review {
  color: #5b0390;
  margin: 0;
  font-size: 14px;
}

.document-card-review-date {
  color: #38414f;
  margin: 0;
}

.document-card-image {
  width: 100px;
}

.document-card-title {
  text-align: center;
  font-weight: 500;
  color: #5b0390;
  font-size: 18px;
  margin: 0;
}
