.dropdown-menu {
  background: #841ea7;
  width: 280px;
  position: absolute;
  top: 80px;
  list-style: none;
  text-align: start;
  z-index: 6;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  border-top-left-radius: 8px;
  animation: dropdown 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  /* z-index: -1; */
}

@keyframes dropdown {
  0% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.dropdown-menu li {
  background: #150442;
  cursor: pointer;
  z-index: 100;
}
.dropdown-menu li:last-child {
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
}

.dropdown-menu li:hover {
  background: #5b0390;
}

.dropdown-menu.clicked {
  display: none;
}

.dropdown-link {
  display: block;
  height: 100%;
  width: 100%;
  text-decoration: none;
  color: #fff;
  padding: 16px;
}

@media screen and (max-width: 960px) {
  .fa-caret-down {
    display: none;
  }
}
