.main-termometer-content * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.main-termometer-content {
  display: flex;
  justify-content: center;
  align-items: center;
}
.skill {
  position: relative;
  width: 160px;
  height: 160px;
  /* background-color: orangered; */
}
.outer {
  height: 160px;
  width: 160px;
  padding: 20px;
  box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
    -6px -6px 10px -1px rgba(255, 255, 255, 0.397);
  border-radius: 50%;
}
.inner {
  height: 120px;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: inset 4px 4px 6px -1px rgba(0, 0, 0, 0.2),
    inset -4px -4px 6px -1px rgba(0, 0, 0, 0.15),
    0px 12px 10px -10px rgba(0, 0, 0, 0.05);
  border-radius: 50%;
}

.progress-number {
  font-weight: 500;
  color: rgb(255, 255, 255);
  font-size: 35px;
}

.circle circle {
  fill: none;
  stroke: url(#GradientColor);
  stroke-width: 20px;
  stroke-dasharray: 450;
  stroke-dashoffset: 450;
}

@keyframes anim {
  100% {
  }
}

.circle {
  position: absolute;
  top: 0;
  left: 0;
}
