@import url("https://fonts.googleapis.com/css2?family=Montserrat&family=Poppins:wght@100;300;400;500;600;700&display=swap");

:root {
  --white: #fff;
  --off-white: #ffe3e3;
  --gradient-pink: #bc0087;
  --gradient-blue: #030093;
  --gradient-purple: #5b0390;
  --hover: #e3c3f6;
}

.body {
  background: linear-gradient(
      270deg,
      rgba(91, 3, 144, 0.7) 0%,
      rgba(110, 2, 132, 0.7) 35%,
      rgba(126, 2, 121, 0.7) 65%,
      rgba(156, 1, 100, 0.7) 98%,
      rgba(178, 0, 86, 0.7) 122%
    ),
    url("../../imgs/pexels-brett-sayles-5092815.jpg");

  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
}

.container {
  width: 100%;
  height: 75%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cabecalho {
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: flex-start;
  justify-content: space-evenly;
  justify-self: space-between;
  padding: 18px 0;
  color: var(--white);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  gap: 20%;
  height: 100px;
}

.box {
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
  /* justify-content: space-around; */
  margin-left: 5%;
  width: 50%;
  height: 100%;
}

.button--container {
  display: flex;
  width: 24%;
  height: 100%;
  justify-content: space-around;
  align-items: center;
  /* padding: 18px; */
}

.lista {
  display: flex;
}

.lista li {
  text-decoration: none;
  list-style: none;
  padding: 0 20px;
}

.lista li:hover {
  display: block;
  cursor: pointer;
  color: var(--hover);
}

.botao-contact {
  height: 60px;
  width: 40%;
  border-radius: 4px;
  background-color: #bc0087;
  color: white;
  font-weight: 500;
  font-size: 14px;
  font-family: "Montserrat";
  border: 2px solid white;
  background-color: #bc00873d;
}
.botao-contact:hover {
  color: var(--hover);
  cursor: pointer;
}

.botao-entrar {
  height: 60px;
  width: 40%;
  border-radius: 4px;
  background-color: #bc0087;
  color: white;
  font-weight: 500;
  font-size: 14px;
  font-family: "Montserrat";
  border: none;
}

.botao-entrar:hover {
  color: var(--hover);
  cursor: pointer;
}

.botao {
  color: var(--white);
  border: solid 1.5px var(--off-white);
  border-radius: 4px;
  /* padding: 3px 34px; */
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  width: 100%;
}

.botao :hover {
  color: #e3c3f6;
}

.botao-2 {
  border: solid 1.5px var(--gradient-pink);
  border-radius: 4px;
  border-color: var(--gradient-pink);
  background-color: var(--gradient-pink);
  /* padding: 2.3px 23px; */
  width: 100%;
}

.logo-cabecalho {
  height: 80%;
}

.logo-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
}

.logo {
  max-width: 80%;
}

.text {
  color: var(--white);
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

@media screen and (max-width: 768px) {
  .cabecalho {
    gap: 10%;
  }
  .logo-cabecalho {
    height: 60%;
  }
  .box {
    width: 15%;
  }
  .button--container {
    width: 100%;
  }
  .lista {
    display: none;
  }
}
