.pannel-main-container {
  height: 100vh;
}

.pannel-container {
  display: grid;
  grid-template-columns: 50% 45%;
  justify-content: space-between;
  align-items: center;
  /* border: 2px solid red; */
  /* height: 100; */
  margin-top: 7%;
}

.pannel-container div {
  /* border: 2px solid red; */
}

.card-grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
  width: 35%;
  height: fit-content;
  justify-content: center;
  /* row-gap: 5%; */
  /* column-gap: 2%; */
  gap: 5%;
  /* border: 2px solid red; */
  margin: auto auto;
}

.grid-item {
  /* background-color: rgba(255, 255, 255, 0.8); */
  /* border: 1px solid rgba(0, 0, 0, 0.8); */
  transition: 0.2s;
}
.grid-item:hover {
  cursor: pointer;
  scale: 1.1;
}
/* Termometer */

.termometer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(
    0deg,
    rgba(21, 4, 66, 1) 0%,
    rgba(190, 0, 255, 1) 100%
  );
  border-radius: 25px;
  width: 80%;
  color: white;
  filter: drop-shadow(-4px 7px 3px #bf00ff57);
  padding: 9% 0%;
  height: 100%;
  animation: slide-fwd-center 0.45s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@keyframes slide-fwd-center {
  0% {
    transform: translateZ(0);
    scale: 0.9;
  }
  100% {
    transform: translateZ(160px);
    scale: 1;
  }
}

.termometer-container * {
  margin: 0;
  box-sizing: border-box;
}

.termometer-title {
  font-size: 45px;
  font-weight: 100;
  margin-bottom: 20px;
}

.termometer-info {
  margin-bottom: 40px;
  width: 80%;
}

@media screen and (max-width: 960px) {
  .card-grid-container {
    grid-template-columns: auto auto;
  }
}

@media screen and (max-width: 768px) {
  .pannel-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* border: 2px solid red; */
  }

  .card-grid-container {
    grid-template-columns: auto;
    gap: 10px;
    /* border: 2px solid red; */
  }

  .grid-item:hover {
    cursor: pointer;
    scale: 1;
  }

  .termometer-title {
    font-size: 35px;
    font-weight: 100;
    margin-bottom: 20px;
  }

  .termometer-info {
    font-size: 1em;
  }
}

.swiper-container {
  width: 100%;
  height: 250px;
  /* border: 2px solid red; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper {
  /* width: 100%; */
  height: 80%;
  /* border: 2px solid red; */
}
